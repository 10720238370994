let cardItems = [
    {
        id:0,
        title:"Video Call",
        link:"video"
    },
    {
        id:1,
        title:"Voice Call",
        link:"voice"
    },
    {
        id:2,
        title:"Message",
        link:"message"
    }
]

export  {cardItems}